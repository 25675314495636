#container-card {
  background-color: white;
  margin: auto;
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 1px #dedede;
  padding-bottom: 50px;
}

@media only screen and (max-width:930px) {
  #container-card {
    width: 97%;
  }
}

.card {
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 40px;
  color: black;
  width: 400px;
  display: flex;
  /* box-shadow: 0px 0px 1px 1px #007bff; */
  padding-top: 10px;
}

.card:hover {
  background-color: white;
}

.card-body {
  padding: 5px 15px;

}

.card-body p {
  padding: 5px;
  color: gray;

}
@media only screen and (max-width:600px){
  .card{
    margin-left: 10px;
  }
}