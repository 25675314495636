#container-fr {
    background-color: white;
    padding: 50px;
    padding-bottom: 80px;
}


 #title-fr {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: gray;
}

#container-cardfr {
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
    width: 70%;

}
@media only screen and (max-width:900px){
    #container-fr {
        padding: 10px;
    }#container-cardfr {
        width: 100%;
    }
}

.card-fr {
    width: 260px;
    padding: 15px;
}

.card-fr img {
    display: block;
    margin: auto;
    width: 50px;
}
@media only screen and (max-width:750px){
    #container-cardfr {
        flex-wrap: wrap;
        margin-top: 0px;
    }
    .card-fr img {
        width: 40px;
    }
    .card-fr{
        margin-top: 20px;
    }
}

.cardfr-text {
    color: gray;
    text-align: center;
    padding-top: 5px;
}