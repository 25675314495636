* {
  margin: 0;
  padding: 0;
}

#app {
  /* background-color: #fafbff; */
  /* background-color: rgb(243, 245, 249); */
  background-color: white;
}

header {
  align-items: center;
  height: 60px;
  background-color: rgb(246, 248, 251);
  display: flex;
  justify-content: space-around;
}

header a {
  text-decoration: none;
  padding: 20px;
  display: block;
  color: rgb(113, 112, 112);
  font-weight: 700;
}

header nav {
  display: flex;
}

#name {
  font-size: 23px;
  font-weight: 700;
  padding: 10px;
}

@media only screen and (max-width:650px) {
  header a {
    font-size: 13px;
    font-weight: 700;
  }

  #name {
    font-size: 17px;
    align-items: center;

  }
}

/*                                             Hamburher Menu     */
#burger-menu {
  margin-top: 5px;
  margin-right: 20px;
  display: none;
  position: fixed;
  right: 10px;
  border-radius: 10px;
  z-index: 1;
  padding: 6px;
}

.line {
  border-radius: 20px;
  height: 3px;
  margin: 5px;
  background-color: #007bff;
  width: 20px;
}

#line2 {
  width: 16px;
}

@media only screen and (max-width:850px) {
  header {
    justify-content: space-between;
  }

  .nav {
    display: none;
  }

  .nav.open {
    display: block;
    flex-direction: column;
    position: fixed;
    right: 4px;
    top: 45px;
    padding: 0px 75px;
    background-color: #007bff;
    z-index: 2;
    border-radius: 20px;
    transition: .3s;
    
  }

  .nav.open a {
    color: rgb(215, 210, 210);
    box-shadow: 0px 4px 8px -8px lightgray;
  }.nav.open a:hover{
    color: white;
  }

  #burger-menu {
    display: block;
  }

  .close {
    padding-top: 5px;
    padding-left: 20px;
  }

  .close #line1 {
    transform: rotate(-46deg) translate(-6px, -1px);
    transition: .3s;
  }

  .close #line2 {
    opacity: 0;
  }

  .close #line3 {
    transform: rotate(43deg) translate(-12px, -5px);
    transition: .3s;
  }
}