#container-home{
    width:100%;
    margin:-50px auto;
    /* background-color: rgb(238, 243, 255); */
    
}
#profile-img{
    border-radius: 350px;
    border-top-left-radius: 0;
    width: 190px;
    margin-left: 100px;
    height: 190px;
}
#title{
    width: max-content;
    font-size: 50px;
    padding:30px 100px;
    display:flex;
    flex-direction:column ;
    font-weight: 700;
}
#title-middle{
    background: -webkit-linear-gradient(black,#05213e,#007bff, #007bff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}
#title span{
    color:#007bff;
}
#techs{
    margin: auto;
    width:90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
/*-  -  -   - - - - - - - - - - - - - - - - - - - - - - - - - -- - - Tech İcons - - -  - - - - - - - - - - - -- - -*/
.img-tech{
    width: 80px;
}
#icon-mongo{
    width:60px;
}
#icon-aws{
    width:40px;
    height: 35px;
}
#icon-mysql{
    width:50px;
    height: 50px;
}
 /*   - - - - - - - - - - - - - - - - - - - - - - - - - -  - - - */


#banner{
    margin:60px auto;
    display:flex;
    justify-content: space-evenly;
    margin-top: 100px;
    width: max-content;
}
#box-left{
    width: 570px;
}
#screens{
   position: relative;
   /* width: 570px; */
}
#container-web{
    border:1px solid lightgray;
    border-radius: 10px;
    width: 570px;
    height:max-content;
    background-color: white;
    box-shadow: 0px 0px 5px 3px #dedede;
}
#header-cw{
   display:flex;
   background-color: rgb(245, 243, 243);
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;

}
.point{
    margin: 4px;
    border-radius: 20px;
    width:8px;
    height: 8px;
    background-color: black;
}
#container-web img{
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
#container-responsive{
    position: relative;
    border:2px solid rgb(78, 76, 76);
    border-radius: 12px;
    width:95px;
    position: absolute;
    bottom:20px;
    right: -25px;
    box-shadow: 4px 3px 4px 0px #dedede;
}
#notch{
    position: absolute;
    width: 35px;
    height: 5px;
    background-color: black;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}
#container-responsive img{
    width:100%;
    height: 100%;
    border-radius: 10px;
}

@media only screen and (max-width:1240px){
    #box-left{
        width: 500px;
    }
    #screens{
        position: relative;
        /* width: 500px; */
     }
    #container-web{
        width: 500px;
    }
    #container-responsive{
        width:90px;
    }#title{
        font-size: 45px;
        font-weight: 700;
        padding-left: 70px;
    }
}
@media only screen and (max-width:1240px){
    .img-tech{
        width: 90px;
        margin-left:10px;
    }
    #icon-aws{
        width:40px;
        height: 35px;
    }
    #icon-mysql{
        width:50px;
        height: 50px;
    }
}
@media only screen and (max-width:1065px){
  #banner{
    width: 100%;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width:600px){
    #box-left{
        width: 99%;
    }
    /* #screens{
        width: 99%;
     } */
    #container-web{
        margin: auto;
        width: 85%;
    }
    #container-responsive{
        width:90px;
        margin-right: 30px;
    }
    #notch{
        width: 25px;
    }
    #title{
        font-size: 38px;
        width: 100%;
        padding:20px 0px;
        text-align: center;
    }
    #box-right{
        width:100%;
    }
    #techs{
        width:100%;
        margin-left: -15px;
    }
    .img-tech{
        width: 70px;
        margin-left:5px;
    }
    #icon-aws{
        width:40px;
        height: 35px;
    }
    #icon-mysql{
        width:40px;
        height: 35px;
    }
  }
  @media only screen and (max-width:550px){  
    
    #container-web{
        margin: auto;
        width: 95%;
    }
    #container-responsive{
        width:70px;
        bottom:10px;
    }
  }
  @media only screen and (max-width:420px){
    #banner{
        margin-top: 70px;
    }
    #title{
        font-size: 30px;
        width: 100%;
        padding:20px 0px;

    }
    .img-tech{
        width: 55px;
        margin-left:5px;
    }
    #icon-mongo{
        width: 48px;
    }
    #icon-aws{
        height:25px ;
        width: 35px;
    }

  } 