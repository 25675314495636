#container-cnt {
    height: min-content;
    border-top: 1px solid rgb(235, 233, 233);
    border-bottom: 1px solid rgb(235, 233, 233);
    background-color: rgb(243, 245, 249);
}
#seperator{
    height: 0px;
    margin:20px auto;
    border-top:1px solid rgb(223, 222, 222) ;
    width: 150px;
}#seperator2{
    margin:0px auto;
    border-top:1px solid rgb(218, 215, 215) ;
    width: 110px;
}
#title-cnt h2 {

    margin-top: 20px;
    font-size: 29px;
    font-weight: 400;
    text-align: center;
    color: rgb(65 64 64);
}

@media only screen and (max-width:600px) {
    #title-cnt h2 {
        font-size: 27px;
    }
}

#icon-cnt {
    margin-top: 20px;
}

#icon-cnt img {
    display: block;
    width: 40px;

}

#body-cnt {
    margin: 20px auto;
    display: flex;
    height: 100px;
    background-color: white;
    width: max-content;
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 80px;
    box-shadow: 0px 2px 2px 1px rgb(244, 241, 241);
}

#mail-cnt {
    margin: 35px 10px;
}

#mail-cnt a {
    color: #007bff;
}