 #container-projects {
     margin: 60px auto;
     /* background-color: #007bff; */
     color: white;
     padding-top: 30px;
     padding-bottom: 30px;
     width: 100%;
     background-color: rgb(243, 245, 249);
 }

 #p-title {
     padding-bottom: 20px;
     text-align: center;
     font-size: 35px;
     color: #14155c;
 }

 #p-container-card {
     width: 99%;
     display: flex;
     justify-content: space-around;
     flex-wrap: wrap;
     /* padding: 10px 20px 30px; */
     /* overflow: auto;
     white-space: nowrap; */
 }

 .d-card {
     height: max-content;
     filter: brightness(0.9);
     position: relative;
     margin-top: 20px;
     border-radius: 20px;
     /* padding-right: 15px;
     padding-bottom: 15px; */
 }

 #scroll-box {
     margin-bottom: 20px;
 }

 @media only screen and (max-width:900px) {
     #p-container-card {
         all: inherit;
         display: flex;
         margin-left: 5px;
     }

     #p-title {
         font-size: 27px;
     }

     #container-projects {
         padding-bottom: 1px;
     }

     #scroll-box {
         overflow-x: auto;
         overflow-y: hidden;
         white-space: nowrap;
     }

     .d-card {
         border-radius: 20px;
         margin-right: 15px;
         margin-bottom: 15px;
     }
 }

 .d-card-img {
     border-radius: 20px;
     height: 330px;
     width: 320px;
 }

 .d-intro {
     position: absolute;
     border-radius: 10px;
     height: 20px;
     width: 90%;
     padding-top: 10px;
     text-align: center;
     align-items: center;
     background: rgba(9, 9, 9, 0.4);
     color: black;
     bottom: 15px;
     left: 0;
     right: 0;
     margin-left: auto;
     margin-right: auto;
     text-decoration: none;
     color:white;
 }

 .d-intro>div {
     margin: auto;
     display: flex;
     width: max-content;
     margin-top: -5px;
 }

 /* @media only screen and (max-width:900px) {
     .d-intro {
         padding-top: 6px;
         height: 20px;
         right: 10px;
     }
 }

 .d-intro>a {
     display: flex;
     justify-content: center;
     color: white;
     text-decoration: none;
     padding-bottom: 5px;
 } */